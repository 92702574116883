import React from 'react';
import Layout from '../components/Layout';
import PostPreview from '../components/PostPreview';
import usePosts from '../hooks/use-posts';
import { css } from '@emotion/core';

// Each page will use <> wrapper to get global styles.

export default () => {
  const posts = usePosts();

  return (
    <>
      <Layout jumbo={true}>
        <ul
          css={css`
            display: -webkit-box;
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
            justify-content: center;
          `}
        >
          {posts.map(post => (
            <PostPreview key={post.slug} post={post}></PostPreview>
          ))}
        </ul>
      </Layout>
    </>
  );
};
